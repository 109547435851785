(function(){function r(e,n,t){function o(i,f){if(!n[i]){if(!e[i]){var c="function"==typeof require&&require;if(!f&&c)return c(i,!0);if(u)return u(i,!0);var a=new Error("Cannot find module '"+i+"'");throw a.code="MODULE_NOT_FOUND",a}var p=n[i]={exports:{}};e[i][0].call(p.exports,function(r){var n=e[i][1][r];return o(n||r)},p,p.exports,r,e,n,t)}return n[i].exports}for(var u="function"==typeof require&&require,i=0;i<t.length;i++)o(t[i]);return o}return r})()({1:[function(require,module,exports){
"use strict";

var _tippy = _interopRequireDefault(require("tippy.js"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }
// tooltip init
(0, _tippy["default"])('[data-tippy-content]');
(0, _tippy["default"])('.tip-content', {
  content: function content(reference) {
    var id = reference.getAttribute('data-template');
    var template = document.getElementById(id);
    return template.innerHTML;
  },
  allowHTML: true,
  trigger: 'click',
  theme: 'light-border',
  interactive: true
});

},{"tippy.js":"tippy.js"}],2:[function(require,module,exports){
"use strict";

// Portal Javascript
// Requires jQuery!
jQuery.ajax({
  url: "https://ardc.atlassian.net/s/d41d8cd98f00b204e9800998ecf8427e-T/tod1zk/b/9/b0105d975e9e59f24a3230a22972a71a/_/download/batch/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector-embededjs/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector-embededjs.js?locale=en-GB&collectorId=427fb7ef",
  type: "get",
  cache: true,
  dataType: "script"
});
window.ATL_JQ_PAGE_PROPS = {
  "triggerFunction": function triggerFunction(showCollectorDialog) {
    //Requires that jQuery is available!
    jQuery(".feedback_button").on('click', function (e) {
      e.preventDefault();
      showCollectorDialog();
    });
  }
};

// Define the showMore function in the global scope
function showMore(shortTextId, fullTextId, morebut, lessbut) {
  // Use jQuery to change the display property
  $('#' + shortTextId).css('display', 'none');
  $('#' + morebut).css('display', 'none');
  $('#' + fullTextId).css('display', 'inline');
  $('#' + lessbut).css('display', 'inline');
}
function showLess(shortTextId, fullTextId, morebut, lessbut) {
  // Use jQuery to change the display property
  $('#' + shortTextId).css('display', 'inline');
  $('#' + morebut).css('display', 'inline');
  $('#' + fullTextId).css('display', 'none');
  $('#' + lessbut).css('display', 'none');
}

// Ensure the DOM is fully loaded before attaching event handlers
$(document).ready(function () {
  // Attach the click event using jQuery
  $('.morebut').on('click', function (event) {
    event.preventDefault(); // Prevent the default anchor behavior
    // Retrieve data attributes
    var shortTextId = $(this).data('shorttextid');
    var fullTextId = $(this).data('fulltextid');
    var morebutId = $(this).attr('id');
    var lessbutId = $(this).data('lessbutid');

    // Call showMore with the appropriate IDs
    showMore(shortTextId, fullTextId, morebutId, lessbutId);
  });
  // Attach the click event using jQuery
  $('.lessbut').on('click', function (event) {
    event.preventDefault(); // Prevent the default anchor behavior
    // Retrieve data attributes
    var shortTextId = $(this).data('shorttextid');
    var fullTextId = $(this).data('fulltextid');
    var morebutId = $(this).data('morebutid');
    var lessbutId = $(this).attr('id');

    // Call showMore with the appropriate IDs
    showLess(shortTextId, fullTextId, morebutId, lessbutId);
  });
});
function changePlaceholderText(selectedOption) {
  selectedOption = selectedOption.trim(); // Remove any whitespace
  switch (selectedOption) {
    case "Researcher":
      $("#search").attr('placeholder', "Search by researcher's name or research expertise");
      break;
    case "Organisation":
      $("#search").attr('placeholder', "Search by organisation name or its focus area");
      break;
    case "Funded Activity":
      $("#search").attr('placeholder', "Search by title, funding provider, or keywords");
      break;
    default:
      $("#search").attr('placeholder', "Search for research and linkages");
  }
}
function showTargetSearchFields() {
  var target = $('#target').val();
  var targetElement = $('#search-advanced-selector_' + target);
  $("div[id*='search-advanced-selector_']:not(.d-none)").addClass("d-none");
  targetElement.removeClass("d-none");
}
function capitaliseWords(inputString) {
  // Split the string into words
  var words = inputString.toLowerCase().split(' ');

  // Capitalize the first letter of each word
  for (var i = 0; i < words.length; i++) {
    words[i] = words[i].charAt(0).toUpperCase() + words[i].substring(1);
  }

  // Join the words back together
  return words.join(' ');
}

// Document Ready
$(function () {
  // Add slideDown animation to Bootstrap nav dropdown when expanding.
  $('#nav-portal .dropdown').on('show.bs.dropdown', function () {
    $(this).find('.dropdown-menu').first().stop(true, true).slideDown('fast');
  });

  // Add slideUp animation to Bootstrap nav dropdown when collapsing.
  $('#nav-portal .dropdown').on('hide.bs.dropdown', function () {
    $(this).find('.dropdown-menu').first().stop(true, true).slideUp('fast');
  });

  // Enable Bootstrap 5 tooltips
  $('[data-bs-toggle="tooltip"]').each(function (index) {
    return new bootstrap.Tooltip($(this));
  });

  // Enable Bootstrap 5 popovers
  $('[data-bs-toggle="popover"]').each(function (index) {
    return new bootstrap.Popover($(this));
  });

  // Show back to top button if scrolled past 100px
  $(window).on('scroll', function () {
    if ($(this).scrollTop() > 100) {
      $('#back-to-top').fadeIn();
    } else {
      $('#back-to-top').fadeOut();
    }
  });

  // Scroll body to 0px on click
  $('#back-to-top').on('click', function () {
    $('body,html').animate({
      scrollTop: 0
    }, 400);
    return false;
  });
  $(".quick-search").on('click', function () {
    $("#search").text($(this).text());
  });

  // JavaScript code to handle icon click event
  $('#advancedSearchButton').on('click', function () {
    showTargetSearchFields();
    $('#advancedSearchModal').modal('show');
  });

  // JavaScript code to handle form submission
  $('#searchButton').on('click', function () {
    //Retrieve form data and perform Elasticsearch search
    var target = document.getElementById('target').value;
    console.log('Clicked on Search button' + target);
    var divNode = document.getElementById('search-advanced-selector_' + target);
    var inputNodes = divNode.querySelectorAll('input, select');
    var aq = '';
    // inputNode.name is research-topic, title, identifier , inputNode.value is what we type
    var first = true;
    inputNodes.forEach(function (inputNode) {
      if (inputNode.type === 'text' && inputNode.value !== '') {
        if (first) {
          aq = aq + inputNode.name + ':' + inputNode.value;
          first = false;
        } else {
          aq = aq + ',' + inputNode.name + ':' + inputNode.value;
        }
      } else if (inputNode.tagName.toLowerCase() === 'select') {
        var selectedOption = inputNode.options[inputNode.selectedIndex].value;
        //console.log('Clicked on Selected Option -->' + selectedOption);
        if (first) {
          aq = aq + inputNode.name + ':' + selectedOption;
          first = false;
        } else {
          aq = aq + ',' + inputNode.name + ':' + selectedOption;
        }
      }
    });
    console.log("&aq=" + aq);
    document.getElementById('advance-search').value = aq;
    document.getElementById('submitBtn').click();
  });
  $('#banner-search-nav .nav-link').on('click', function (e) {
    e.preventDefault(); // Stops the link adding # to url
    var value = $(this).attr('data-value');
    var title = $(this).attr('data-title');
    // $('#selected-option').text(title);
    $('#banner-search-nav .nav-link.active').removeClass("active");
    $(this).addClass("active");
    changePlaceholderText(title);
    $('#target').val(value);
    showTargetSearchFields();
  });
  $('.search-dropdown').on('click', function (e) {
    e.preventDefault(); // Stops the link adding # to url
    var value = $(this).attr('data-value');
    var title = $(this).attr('data-title');
    $('#selected-option').text(title);
    changePlaceholderText(title);
    $('#target').val(value);
    showTargetSearchFields();
  });
});
document.addEventListener("DOMContentLoaded", function () {
  // Get the dropdown menu element
  var dropdownMenu = document.querySelector('.dropdown-menu.dashboard-selector');
  if (dropdownMenu != null) {
    // Function to set iframe src and clear filters
    var setIframeSrcAndClearFilters = function setIframeSrcAndClearFilters(selectedId) {
      // Check if selectedId is valid
      if (selectedId) {
        // Clear filters by modifying the URL
        var clearedUrl = clearFilters(selectedId);
        iframe.src = clearedUrl;
      } else {
        console.error("Invalid selectedId:", selectedId);
      }
    };
    var clearFilters = function clearFilters(url) {
      // Check if URL already contains query parameters
      if (url.indexOf('?') !== -1) {
        // Append '&' if query parameters already exist
        return url + '&_g=(filters:!())';
      } else {
        // Append '?' if no query parameters exist
        return url + '?_g=(filters:!())';
      }
    }; // Set initial iframe src and clear filters
    // Get all dropdown items within the dropdown menu
    var dropdownItems = dropdownMenu.querySelectorAll('.dropdown-item-db');

    // Get the dropdown button element
    var dropdownButton = document.getElementById("dbdropdownMenuButton");

    // Get the iframe element
    var iframe = document.getElementById("dashboard-iframe");
    setIframeSrcAndClearFilters(dropdownItems[0].getAttribute('data-value'));

    // Add the 'selected' attribute to the first dropdown item
    dropdownItems[0].setAttribute('selected', 'selected');

    // Set the dropdown button text to the text of the selected option
    dropdownButton.textContent = dropdownItems[0].textContent;
    // Add event listener to each dropdown item
    dropdownItems.forEach(function (item) {
      item.addEventListener("click", function () {
        // Get selected option value
        var selectedId = this.getAttribute('data-value');
        // Set iframe src and clear filters
        setIframeSrcAndClearFilters(selectedId);
        document.querySelector('#dbdropdownMenuButton').textContent = this.getAttribute('data-title');
      });
    });
  }
  // Function to create the bar chart
  function createBarChart() {
    // Access the JSON data embedded in the HTML
    if (typeof vocabData != 'undefined') {
      // Sort the data by count in descending order
      var filteredData = vocabData.filter(function (d) {
        return d.label !== null && d.label !== undefined;
      });
      var topData = null;
      // Get the top 5 counts
      if (filteredData.length > 5) {
        topData = filteredData.slice(0, 5);
      } else {
        topData = filteredData;
      }

      // Set up the SVG dimensions and margins
      var margin = {
        top: 20,
        right: 20,
        bottom: 30,
        left: 40
      };
      var containerWidth = document.getElementById('bar-chart-container').offsetWidth;
      var containerHeight = 300;
      var width = containerWidth - margin.left - margin.right;
      var height = containerHeight - margin.top - margin.bottom;

      // Remove existing SVG if any
      d3.select("#bar-chart-container svg").remove();

      // Set up scales
      var xScale = d3.scaleLinear().domain([0, d3.max(topData, function (d) {
        return d.count;
      })]).range([0, width]);
      var yScale = d3.scaleBand().domain(topData.map(function (d) {
        return d.label;
      })).range([0, height]).padding(0.1);

      // Create the SVG container
      var svg = d3.select("#bar-chart-container").append("svg").attr("width", width + margin.left + margin.right).attr("height", height + margin.top + margin.bottom).append("g").attr("transform", "translate(" + margin.left + "," + margin.top + ")");

      // Create a color scale for fading bars (reversed order)
      var colorScale = d3.scaleLinear().domain([d3.max(topData, function (d) {
        return d.count;
      }), 0]) // Reverse the order
      .range(["#8E489B", "#f8b20e"]);

      // Create the horizontal bars with fading purple color
      svg.selectAll(".bar").data(topData).enter().append("rect").attr("class", "bar").attr("width", function (d) {
        return xScale(d.count);
      }).attr("height", yScale.bandwidth()).attr("y", function (d) {
        return yScale(d.label);
      }).attr("fill", function (d) {
        return colorScale(d.count);
      });

      // Add labels with count within the bars (left-justified and with padding)
      svg.selectAll(".label").data(topData).enter().append("text").attr("class", "label").attr("x", 5) // Add 5px padding to the left
      .attr("y", function (d) {
        return yScale(d.label) + yScale.bandwidth() / 2;
      }).attr("dy", "0.35em") // Adjust vertical alignment
      .attr("text-anchor", "start") // Left-justify text
      .attr("fill", "white") // Set text color to white
      .text(function (d) {
        return d.label;
      });

      // Add count labels at the right end of each bar (right-justified)
      svg.selectAll(".count-label").data(topData).enter().append("text").attr("class", "count-label").attr("x", function (d) {
        return xScale(d.count) - 5;
      }).attr("y", function (d) {
        return yScale(d.label) + yScale.bandwidth() / 2;
      }).attr("dy", "0.35em") // Adjust vertical alignment
      .attr("text-anchor", "end") // Right-justify text
      .attr("fill", "white") // Set text color to white
      .text(function (d) {
        return "(".concat(d.count, ")");
      });
    }
  }

  // Call the createBarChart function initially
  createBarChart();

  // Function to handle resizing of the window
  function rescale() {
    // Call the createBarChart function to redraw the chart
    createBarChart();
  }

  // Listen for window resize events
  window.addEventListener("resize", rescale);
});

},{}]},{},[1,2]);
